import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import DishItem from './DishItem';
import * as uiParams from '../../common/uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { LaneData } from '../../../api/kitchen';
import { OrderWithLanesData } from '../../../utils/kitchen/sortHelper';

interface Props {
  title: string;
  laneId: keyof LaneData;
  orders: OrderWithLanesData[];
  classes: ClassNameMap;
  onShowRecipeButtonClick: (dish: OrderWithLanesData) => void;
  isLoading: boolean;
  onCardArrowClick: (
    targetLane: keyof LaneData,
    orders: OrderWithLanesData[]
  ) => void;
  onCardClick: (orders: OrderWithLanesData[]) => void;
}

function DishLane(props: Props) {
  const ordersByItemNo = props.orders.reduce<{
    [itemNo: string]: OrderWithLanesData[];
  }>((result, order) => {
    const itemNo = (() => {
      if (order.itemNo.startsWith('FORC-')) {
        return order.itemNo.slice(5);
      } else {
        return order.itemNo;
      }
    })();

    if (itemNo in result) {
      result[itemNo].push(order);
    } else {
      result[itemNo] = [order];
    }

    return result;
  }, {});

  const totalDishes = Object.values(ordersByItemNo).reduce(
    (sum, orders) =>
      sum +
      orders.reduce(
        (sum, order) =>
          sum +
          order.lanesData.normalPackaging[props.laneId] +
          order.lanesData.reusablePackaging[props.laneId],
        0
      ),
    0
  );

  return (
    <div
      className={clsx(props.classes.laneStyle, {
        [props.classes.processingLane]: props.laneId === 'inProcessing',
        [props.classes.kitchenLane]: props.laneId === 'inKitchen',
        [props.classes.completedLane]: props.laneId === 'inCompleted'
      })}
    >
      <header className={props.classes.header}>
        <h3 className={props.classes.title}>{props.title}</h3>
        <span>{totalDishes} Dishes</span>
        <span>{Object.keys(ordersByItemNo).length} Types</span>
      </header>
      <section className={props.classes.itemWrapper}>
        {Object.entries(ordersByItemNo).map(([itemNo, orders]) => (
          <DishItem
            key={itemNo}
            orders={orders}
            isLoading={props.isLoading}
            currentLane={props.laneId}
            newTag={false}
            onShowRecipeButtonClick={() =>
              props.onShowRecipeButtonClick(orders[0])
            }
            onCardArrowClick={(targetLane) =>
              props.onCardArrowClick(targetLane, orders)
            }
            onCardClick={() => props.onCardClick(orders)}
          />
        ))}
      </section>
    </div>
  );
}

const styles: Styles<Theme, {}> = {
  laneStyle: {
    minWidth: uiParams.LANE_WIDTH,
    width: 'calc(33.333% - 10px)',
    color: uiParams.BLACK,
    marginRight: uiParams.LANE_MARGIN,
    backgroundColor: uiParams.GRADIENT_SEC,
    padding: '15px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    maxHeight: 'calc(100vh - 200px)',
    minHeight: '50vh',
    marginBottom: '20px'
  },
  header: {
    fontSize: '0.9rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: { margin: 0 },
  itemWrapper: {
    overflowY: 'auto',
    minWidth: '250px',
    overflowX: 'hidden',
    alignSelf: 'center',
    maxHeight: 'calc(100% - 40px)',
    marginTop: '10px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 1 0%',
    borderRadius: '3px'
  },
  processingLane: {
    color: uiParams.BLACK,
    backgroundColor: uiParams.GREY3
  },
  kitchenLane: {
    color: '#fff',
    backgroundColor: uiParams.GRADIENT_SEC
  },
  completedLane: {
    color: '#fff',
    backgroundColor: uiParams.PRIMARY
  }
};

export default withStyles(styles)(DishLane);
