import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OrderForecastContext from '..';
import {
  getDishesOrdersForWeek,
  getForecastData,
  getMenu
} from '../../../../api/kitchen';
import { weekData } from '../../../../config/constants';
import { getInitialSelectedDate } from '../../../../utils/commonUtils';

const KitchenCountContextProvider = ({ children }) => {
  const [forecastData, setForecastData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getInitialSelectedDate());
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getMenu(moment(selectedDate).format('YYYY-MM-DD')).then((res) => {
      setMenu(res);
    });
    getForecastData(moment(selectedDate).format('YYYY-MM-DD')).then((res) => {
      setForecastData(res);
    });
    getDishesOrdersForWeek(moment(selectedDate).format('YYYY-MM-DD')).then(
      (res) => {
        setOrderData(res.dishes);
      }
    );
  }, [selectedDate]);

  useEffect(() => {
    if (menu && menu.length > 0) {
      const data = [];
      const days = {};
      const day = moment(selectedDate).startOf('isoWeek');
      for (let i = 1; i <= 6; i += 1) {
        days[`${day.format('ddd')}`] = day.format('YYYY-MM-DD');
        day.add(1, 'day');
      }
      menu.map((dish) => {
        const totalForecastQuantity = [];
        const totalActualOrderQuantity = [];
        const remainingForecastQuantity = [];
        const forecastedWeekData = {};
        // complete weekData
        weekData
          .map((d) => d.name)
          .map((key) => {
            totalForecastQuantity[key] = forecastData.filter(
              (fd) =>
                fd.itemNo.indexOf(dish.itemNo) > -1 &&
                fd.deliveryDate === days[key]
            )?.[0]?.forecastedQuantity;

            remainingForecastQuantity[key] = forecastData.filter(
              (fd) =>
                fd.itemNo.indexOf(dish.itemNo) > -1 &&
                fd.deliveryDate === days[key]
            )?.[0]?.quantity;

            totalActualOrderQuantity[key] = orderData
              .filter(
                (d) =>
                  d.itemNo.indexOf(dish.itemNo) > -1 &&
                  d.deliveryDate === days[key]
              )
              .reduce((acc, cv) => acc + cv.quantity, 0);

            forecastedWeekData[key] = {
              deliveryDate: days[key],
              quantity: remainingForecastQuantity[key] || 0,
              totalQuantity: totalForecastQuantity[key] || 0,
              actualOrderQuantity: totalActualOrderQuantity[key] || 0,
              isToday: moment(new Date()).format('YYYY-MM-DD') === days[key]
            };
            return key;
          });

        data.push({
          id: dish.id,
          name: dish.name,
          itemNo: dish.itemNo,
          stock: dish.stock,
          category: dish.categoryName,
          ...forecastedWeekData
        });

        return dish;
      });
      /**
       * https://www.geeksforgeeks.org/move-all-values-equal-to-k-to-the-end-of-the-array/
       */
      // Function to move the element to the end
      const moveAddonsToEnd = (array) => {
        // Mark left pointer
        let i = 0;

        // Mark the right pointer
        let j = array.length - 1;

        // Iterate until left pointer
        // crosses the right pointer
        while (i < j) {
          while (i < j && array[j].category === 'Addons') {
            // Decrement right pointer
            // eslint-disable-next-line no-plusplus
            j--;
          }
          if (array[i].category === 'Addons') {
            // Swap the two elements
            // in the array
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
          }
          // Increment left pointer
          // eslint-disable-next-line no-plusplus
          i++;
        }
        // Return the result
        return array;
      };
      setTableData(moveAddonsToEnd(data));
    } else {
      setTableData([]);
    }
  }, [menu, forecastData, orderData, selectedDate]);

  return (
    <OrderForecastContext.Provider
      value={{
        forecastDataState: [forecastData, setForecastData],
        menuDataState: [setMenu],
        menu,
        tableDataState: [tableData, setTableData],
        dateState: [selectedDate, setSelectedDate]
      }}
    >
      {children}
    </OrderForecastContext.Provider>
  );
};

export default KitchenCountContextProvider;
