import React, { forwardRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { getStickerUrl } from '../../utils/commonUtils';
import BellaBona from '../../assets/BellaBona.png';
import { styles } from './css/component-print-style';
import './css/dispatcher-tabs.css';
import { GENERIC_STICKER_THUMBNAIL } from '../../config/constants';
import { DispatcherBox, DispatcherBranch } from './domain';
import { paginateList } from './helpers';

interface Props {
  branch: DispatcherBranch;
  deliveryDate: Date;
}

interface Row {
  boxType: DispatcherBox['type'];
  username: string;
  dishLabel: string;
  dishName: string;
  quantity: number;
}

const SinglePrintComponent = forwardRef<HTMLDivElement, Props>(
  function SinglePrintComponent(props, ref) {
    const rows: Row[] = props.branch.boxes.flatMap((box) =>
      box.dishes.flatMap((dish) =>
        dish.users.map((user) => ({
          boxType: box.type,
          username: user.username,
          dishLabel: dish.label,
          dishName: dish.name,
          quantity: user.orderedQuantity
        }))
      )
    );

    const coldDishes = rows
      .filter((row) => row.boxType === 'cold' && row.dishLabel !== 'addons')
      .sort((a, b) => a.username.localeCompare(b.username));

    const hotDishes = rows
      .filter((row) => row.boxType === 'hot' && row.dishLabel !== 'addons')
      .sort((a, b) => a.username.localeCompare(b.username));

    const addOns = rows
      .filter((row) => row.dishLabel === 'addons')
      .sort((a, b) => a.username.localeCompare(b.username));

    const pages = [
      ...paginateList(addOns, 10).map((page, index, list) => ({
        name: 'Addons',
        rows: page,
        pageHeader: `Page ${index + 1} of ${list.length}`
      })),
      ...paginateList(coldDishes, 10).map((page, index, list) => ({
        name: 'Cold Dishes',
        rows: page,
        pageHeader: `Page ${index + 1} of ${list.length}`
      })),
      ...paginateList(hotDishes, 10).map((page, index, list) => ({
        name: 'Hot dishes',
        rows: page,
        pageHeader: `Page ${index + 1} of ${list.length}`
      }))
    ];

    return (
      <div ref={ref}>
        {pages.map((page, index) => (
          <div style={styles.container} key={index}>
            <img src={BellaBona} style={styles.imageStyle} alt="Bella & Bona" />
            <div style={styles.clientInfo}>
              <div>{props.branch.deliveryName || props.branch.name}</div>
              <div>
                {props.deliveryDate.toLocaleDateString('en-US', {
                  weekday: 'long'
                })}{' '}
                {props.deliveryDate.toLocaleDateString('de-DE')}
              </div>
            </div>
            <div style={styles.table}>
              <div className="pageBreak">
                <div style={styles.dishCategory}>
                  <span>{page.name}</span>
                  <span>{page.pageHeader}</span>
                </div>

                <Table style={{ borderCollapse: 'collapse' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableCell} className="bold">
                        Customer Name
                      </TableCell>
                      <TableCell style={styles.tableCell} className="bold">
                        SKU
                      </TableCell>
                      <TableCell style={styles.tableCell} className="bold">
                        Dish Name
                      </TableCell>
                      <TableCell style={styles.tableCell} className="bold">
                        Quantity
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {page.rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell style={styles.tableCell}>
                          {row.username}
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <img
                            style={styles.lidImage}
                            src={getStickerUrl(
                              row.dishLabel === 'addons' ? 'AD' : 'DL',
                              row.dishLabel
                            )}
                            alt="Bella&Bona"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = GENERIC_STICKER_THUMBNAIL;
                            }}
                          />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          {row.dishName}
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          {row.quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell style={styles.tableCell} colSpan={2} />
                      <TableCell style={styles.tableCell} className="bold">
                        Total
                      </TableCell>
                      <TableCell style={styles.tableCell} className="bold">
                        {page.rows.reduce((sum, row) => sum + row.quantity, 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default SinglePrintComponent;
